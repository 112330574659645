import { HttpClient, HttpParams } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { IEditUser, IPhoneVerificationResult, ITrainingSsoResponse, IUpdateDealerRoleModel, IUsageResult, IUser, IUserCertification, LanguageType, SearchModel } from "@app/account/account.models";
import { IPasswordResetResponseModel } from "@app/auth/auth.models";
import { ContractType, IContractModel, IGenericErrorListResponse, IGenericResponse } from "@app/shared/shared.models";
import { IAppState } from "@app/store";
import { ErrorToast, InfoToast, SuccessToast } from "@app/store/toast/toast.actions";
import { getUserState, UserState } from "@app/store/user";
import { ISalesforceInfo } from "@app/support/support.models";
import { CertificationType, ICertificationTraineeUserModel, IPcnaTraineeInfo, PCNAType } from "@app/training/training.models";
import { Dictionary } from "@ngrx/entity/src/models";
import { select, Store } from "@ngrx/store";
import { saveAs } from "file-saver";
import { Observable, of } from "rxjs";

@Injectable({ providedIn: "root" })
export class UserService {
	private userEntities: Dictionary<IUser>;

	displayUserProfileEdit = new EventEmitter<boolean>();

	constructor(private http: HttpClient, private userStore: Store<UserState>, private store: Store<IAppState>) {
		this.userStore.pipe(select(getUserState)).subscribe(state => this.userEntities = state.entities);
	}

	get(userId: string, overrideStore?: boolean): Observable<IUser> {
		return overrideStore || !this.userEntities[userId]
			? this.http.get<IUser>("api/user/getUser", { params: { userId } })
			: of(this.userEntities[userId] as IUser);
	}

	getSalesforceInfo() {
		return this.http.get<ISalesforceInfo>("api/user/GetSalesforceInfo");
	}

	updateProfile(user: IUser, sfInfo: ISalesforceInfo): Observable<IUser> {
		return this.http.post<IUser>("api/user/editProfile", { user, sfInfo });
	}

	saveUser(user: IEditUser) {
		return this.http.post<IGenericResponse<IUser>>("api/user/EditUser", user);
	}

	updateUserSalesforceInfo(userId: String, salesforceContactId: String, salesforceUserId: String) {
		return this.http.post<IGenericErrorListResponse>("api/user/UpdateUserSalesforceInfo", { userId, salesforceContactId, salesforceUserId });
	}

	removeUser(userId: string) {
		return this.http.post<IGenericResponse>("api/user/RemoveUser", { userId });
	}

	addUser(user: IEditUser) {
		return this.http.post<IGenericResponse<IUser>>("api/user/addUser", user);
	}

	addInternalUser(user: IEditUser) {
		return this.http.post<IGenericResponse<IUser>>("api/user/addInternalUser", user);
	}

	changeUsername(newUsername: string) {
		return this.http.post<IGenericResponse>("api/user/ChangeUsername", { newUsername });
	}

	getCurrentContract(contractType: ContractType) {
		return this.http.get<IContractModel>("api/user/GetCurrentContract", {
			params: { contractType: contractType.toString() },
		});
	}

	getLanguages() {
		return this.http.get<LanguageType[]>("api/user/GetLanguages");
	}

	signContract(id: number) {
		return this.http.post<boolean>("api/user/SignContract", null, { params: { id: id.toString() } });
	}

	requestPasswordReset(loginName: string, sendEmail: boolean) {
		return this.http.get<IPasswordResetResponseModel>("api/user/RequestPasswordReset", { params: { loginName, sendEmail: sendEmail.toString() } });
	}

	getUserCertifications() {
		return this.http.get<IUserCertification[]>("api/training/getUserCertifications");
	}

	beginPhoneVerification(phoneNumber: string, overrideInUse: boolean) {
		return this.http.post<IPhoneVerificationResult>("api/user/BeginPhoneVerification", { phoneNumber, overrideInUse: !!overrideInUse });
	}

	retryPhoneVerification(requestId: string, phoneNumber: string, overrideInUse: boolean) {
		return this.http.post<IPhoneVerificationResult>("api/user/RetryPhoneVerification", { requestId, phoneNumber, overrideInUse: !!overrideInUse });
	}

	checkPhoneVerificationCode(requestId: string, verificationCode: string, phoneNumber: string) {
		return this.http.post<IPhoneVerificationResult>("api/user/CheckPhoneVerificationCode", { requestId, verificationCode, phoneNumber });
	}

	beginEmailVerification(email: string, isPersonal: boolean) {
		return this.http.post<IUsageResult>("api/user/BeginEmailVerification", { email, isPersonal: !!isPersonal });
	}

	checkEmailVerificationCode(token: string, email: string, isPersonal: boolean) {
		return this.http.post<IGenericResponse>("api/user/CheckEmailVerificationCode", { token, email, isPersonal: !!isPersonal });
	}

	searchUsers(searchText: string, dealerId: string, pageNum: number, totalPerPage: number) {
		let params = new HttpParams().append("pageNum", pageNum.toString()).append("totalPerPage", totalPerPage.toString());
		if (searchText) {
			params = params.append("searchText", searchText);
		}
		if (dealerId) {
			params = params.append("dealerId", dealerId);
		}

		return this.http.get<SearchModel<IUser>>("api/user/SearchUsers", { params });
	}

	getUserPcnaProgress(loginName?: string) {
		let params = new HttpParams();
		if (loginName) {
			params = params.append("loginName", loginName);
		}

		return this.http.get<ICertificationTraineeUserModel>("api/training/GetUserPcnaProgress", { params });
	}
	getUserC4CTProgress(loginName?: string) {
		let params = new HttpParams();
		if (loginName) {
			params = params.append("loginName", loginName);
		}

		return this.http.get<ICertificationTraineeUserModel>("api/training/GetUserC4CTProgress", { params });
	}

	userHasNewPcnaKit(userId: string) {
		const params = new HttpParams().append("userId", userId);
		return this.http.get<boolean>("api/user/UserHasNewPcnaKit", { params });
	}
	userHasNewC4CTKit(userId: string) {
		const params = new HttpParams().append("userId", userId);
		return this.http.get<boolean>("api/user/UserHasNewC4CTKit", { params });
	}

	getPcnaTraineeInfo() {
		return this.http.get<IPcnaTraineeInfo>("api/training/GetAccountPcnaTrainees");
	}
	getc4ctTraineeInfo() {
		return this.http.get<IPcnaTraineeInfo>("api/training/GetAccountc4ctTrainees");
	}

	getPcnaTechnicianBakpakCode() {
		return this.http.get("api/training/GetTechnicianBakpakCode", { responseType: "text" });
	}
	

	getDoceboSsoUrl(destinationUrl: string) {
		return this.http.get<ITrainingSsoResponse>("api/training/GetDoceboSsoUrl", { params: { destinationUrl } });
	}

	resetRebateStartDate(loginName: string, date: Date) {
		return this.http.post<IGenericErrorListResponse>(`api/training/ResetRebateStartDate`, { loginName, rebateStartDate: date });
	}

	transferPcnaKit(fromLoginName: string, toLoginName: string) {
		return this.http.post<IGenericErrorListResponse>("api/training/TransferPcnaKit", { fromLoginName, toLoginName });
	}

	registerUserForPcna(userToRegister: string, pcnaType?: PCNAType) {
		return this.http.post<IGenericErrorListResponse>("api/training/RegisterUserForPcna", { toLoginName: userToRegister, pcnaType });
	}

	unregisterUserForPcnaLearningPath(userToUnregister: string, pcnaType?: PCNAType) {
		return this.http.post<any>("api/training/UnregisterUserForPcnaLearningPath", { toLoginName: userToUnregister, pcnaType });
	}

	unregisterUserForPcnaCourses(userToUnregister: string, pcnaType?: PCNAType) {
		return this.http.post<IGenericErrorListResponse>("api/training/UnregisterUserForPcnaCourses", { toLoginName: userToUnregister, pcnaType });
	}

	downloadCert(cert: IUserCertification, technicianName: string) {
		this.store.dispatch(new InfoToast({ message: "Certificate downloading..." }));

		return this.http.get("api/user/DownloadCert", {
			params: { certificationType: cert.certificationType.toString(), contactName: cert.contactName, completionDate: new Date(cert.completionDate).toISOString() },
			responseType: "blob",
		}).subscribe(data => {
			if (data) {
				saveAs(data, `${technicianName}-${this.mapCertName(cert.certificationType)}.pdf`);
				this.store.dispatch(new SuccessToast({ message: "Certificate downloaded." }));
			} else {
				this.store.dispatch(new ErrorToast({ message: "Certificate could not be downloaded." }));
			}
		});
	}

	getCertificate(certs: IUserCertification[], type: CertificationType) {
		return certs.find(cert => cert.certificationType === type) || <IUserCertification>{ certificationType: type };
	}

	updateDealerRoles(model: IUpdateDealerRoleModel) {
		return this.http.put<boolean>("api/user/UpdateDealerRoles", model);
	}

	private mapCertName(certType: CertificationType) {
		switch (certType) {
			case CertificationType.CentralizedLighting2012:
				return "CentralizedLighting";
			case CertificationType.CentralizedLighting2019:
				return "CentralizedLighting";
			case CertificationType.AssociateInstaller:
				return "AssociateInstaller";
			case CertificationType.AutomationProgrammer:
				return "AutomationProgrammer";
			case CertificationType.Pcna:
				return "PCNA";
			case CertificationType.Control4CertifiedTechnician:
				return "CertifiedTechnician";
		}
	}
}