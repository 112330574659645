import { KeyValue } from "@angular/common";
import { IOrderConfirmation } from "@app/checkout/checkout.models";
import { IGenericErrorListResponse, Portals } from "@app/shared/shared.models";
import { Dictionary } from "@ngrx/entity";

export module PcnaHelper {
	export const PcnaCourseIds = new Map<Portals, number[]>([
		[Portals.DealerUs, [
			482508,
			491588,
			482513,
			482514,
			482516,
			482520,
		]],
		[Portals.DealerEmea, [
			482508,
			491588,
			482513,
			482514,
			482516,
			482520,
		]],
		[Portals.Pakedge, [
			518606,
			518603,
			518560,
			518566,
			518568,
			518588,
		]],
		[Portals.Triad, [
			518596,
			518594,
			518576,
			518584,
			518587,
			518592,
		]],
	]);
}

export module C4CTHelper {
	export const C4CTCourseIds = new Map<Portals, number[]>([
		[Portals.DealerUs, [
			482508,

		]],
		[Portals.DealerEmea, [
			482508,

		]],
		[Portals.Pakedge, [
			518606,

		]],
		[Portals.Triad, [
			518596,
		]],
	]);
}

export const LmsUrls = new Map<Portals, string>([
	[Portals.DealerUs, "education.control4.com"],
	[Portals.DealerEmea, "education.control4.com"],
	[Portals.Pakedge, "pakedge.learnupon.com"],
	[Portals.Triad, "triad.learnupon.com"],
]);

export enum CertificationType {
	AssociateInstaller = 1,
	AutomationProgrammer = 2,
	CentralizedLighting2012 = 3,
	Pcna = 4,
	CentralizedLighting2019 = 5,
	Control4CertifiedTechnician = 6,
}

export module CertificationType {
	const prettyNames = {
		1: "Associate Installer",
		2: "Automation Programmer",
		3: "Centralized Lighting (2012)",
		4: "Professional Certified Network Associate",
		5: "Centralized Lighting (2019)",
		6: "Control4 Certified Technician",
	} as Dictionary<string>;

	const shortNames = {
		1: "Associate Installer",
		2: "Automation Programmer",
		3: "Centralized Lighting",
		4: "PCNA",
		5: "Centralized Lighting",
		6: "Control4 Certified",
	} as Dictionary<string>;

	const abbreviations = {
		1: "ai",
		2: "ap",
		3: "cl",
		4: "pcna",
		5: "cl",
		6: "c4ct",
	} as Dictionary<string>;

	const chartColors = {
		1: "#ed803a",
		2: "#a82c35",
		3: "#89b525",
		4: "#4f8dc8",
		5: "#89b525",
		6: "#tbd",  //color to be decided
	} as Dictionary<string>;

	export function getPrettyNames(): any[] {
		const names = [];

		for (const key in Object.keys(prettyNames)) {
			if (prettyNames.hasOwnProperty(key)) {
				names.push({ name: prettyNames[key], value: parseInt(key, 10) });
			}
		}

		return names;
	}

	export function getPrettyName(certType: CertificationType) {
		return prettyNames[certType];
	}

	export function getShortName(certType: CertificationType) {
		return shortNames[certType];
	}

	export function getAbbreviation(certType: CertificationType) {
		return abbreviations[certType];
	}

	export function getEnumVal(certTypeName: string): CertificationType {
		let val = 0;
		Object.keys(prettyNames).forEach(key => {
			if (prettyNames[key] === certTypeName) {
				val = parseInt(key, 10);

				return;
			}
		});

		return val;
	}

	export function getChartColor(certType: CertificationType) {
		return chartColors[certType];
	}

	function getImagePath(certType: CertificationType) {
		switch (certType) {
			case CertificationType.Pcna:
				return "pcna-logo-primary-2020.png";
			case CertificationType.CentralizedLighting2012:
				return "certification_centralizedlighting.png";
			case CertificationType.CentralizedLighting2019:
				return "certification_centralizedlighting2019.png";
			case CertificationType.AssociateInstaller:
				return "certification-installer.png";
			case CertificationType.AutomationProgrammer:
				return "certification-programmer.png";
			case CertificationType.Control4CertifiedTechnician:
				return "certification-technician.png";
		}
	}

	export function getImageUrl(certType: CertificationType, transformations: string, certified: boolean) {
		const imgPath = getImagePath(certType);
		transformations = transformations || "c_scale";
		if (!certified) {
			transformations += ",e_grayscale,o_33";
		}
		if (certType == CertificationType.Control4CertifiedTechnician) {
			if (!certified)
				return `https://res.cloudinary.com/control4/image/upload/v1742337893/static-pages/C4CT/C4CT_Badge_Full_Web_Grayscale.png`
			else
				return ` https://res.cloudinary.com/control4/image/upload/v1742337893/static-pages/C4CT/C4CT_Badge_Full_Web_Color.png`
		}
		else
			return `https://res.cloudinary.com/control4/image/upload/${transformations}/${imgPath}`;
	}
}

export enum CertificationStatus {
	Unregistered = 0,
	Registered = 10,
	Shipped = 15,
	CourseCompleted = 20,
	ExamScheduled = 30,
	ExamComplete = 35,
	ExamFailed = 40,
	Certified = 50,
}

export enum PCNAType {
	Pakedge = 1,
	Araknis = 2,
}

export module PCNATypeSkus {
	const skus = {
		1: "PCNA-PE-KIT",
		2: "PCNA-AN-KIT",
	} as Dictionary<string>;

	export function getPCNATypeSku(pcnaType: PCNAType) {
		return skus[pcnaType];
	}
}

export module PCNATypeNames {
	const prettyNames = {
		1: "Pakedge",
		2: "Araknis",
	} as Dictionary<string>;

	export function getPCNATypePrettyName(pcnaType: PCNAType) {
		return prettyNames[pcnaType];
	}
}

export enum C4CTType {
	C4CTUS = 1,
	C4CTEMEA = 2,
}

export module C4CTTypeSkus {
	const skus = {
		1: "C4CT-KIT-US",
		2: "C4CT-KIT-EMEA",
	} as Dictionary<string>;

	export function getC4CTTypeSku(C4CTType: C4CTType) {
		return skus[C4CTType];
	}
}

export module C4CTTypeNames {
	const prettyNames = {
		1: "USC4CT",
		2: "EMEAC4CT",
	} as Dictionary<string>;

	export function getC4CTTypePrettyName(C4CTType: C4CTType) {
		return prettyNames[C4CTType];
	}
}
export module CertificationStatus {
	const prettyNames = {
		0: "Unregistered",
		10: "Registered",
		15: "In Progress",
		20: "Courses Completed",
		30: "Exam Scheduled",
		35: "Awaiting Grade",
		40: "Exam Failed",
		50: "Certified",
	} as Dictionary<string>;

	export function getPrettyNames(): any[] {
		const names = [];

		for (const key in Object.keys(prettyNames)) {
			if (prettyNames.hasOwnProperty(key)) {
				names.push({ name: prettyNames[key], value: parseInt(key, 10) });
			}
		}

		return names;
	}

	export function getPrettyName(status: CertificationStatus) {
		return prettyNames[status];
	}
}

export interface ICertificationTraineeUserModel extends IGenericErrorListResponse {
	firstName: string;
	lastName: string;
	fullName: string;
	userId: string;
	accountId: string;
	email: string;
	portalAccess: Portals;
	orderId: number;
	orderDetailId: number;
	enrollmentId: number;
	lastCourse: number;
	currentStatus: CertificationStatus;
	rebateStartDate: string;
	completionDate: string;
	salesforceUserId: string;
	salesforceContactId: string;
	technicianId: string;
	creationDate: string;
	courses: ITrainingCourseStatus[];
	examPartOne: ITrainingCourseStatus;
	examPartTwo: ITrainingCourseStatus;
	pcnaType: PCNAType;
	c4ctType: C4CTType
}

export interface ITrainingCourseStatus {
	id: number;
	name: string;
	status: string;
	url: string;
	image: string;
	score: number;
	completedDate: string;
	completed: boolean;
	passed: boolean;
}

export interface ITrainingSchedule {
	countries: string[];
	educationFacilities: KeyValue<string, string>[];
	sessions: ITrainingSession[];
}

export interface ITrainingSession {
	id: number;
	title: string;
	createdDate: Date;
	updatedDate: Date;
	componentType: string;
	descriptionHtml: string;
	descriptionText: string;
	locationId: number;
	locationName: string;
	address1: string;
	address2: string;
	address3: string;
	locationCountryCode: string;
	locationStateCode: string;
	startDate: Date;
	endDate: Date;
	timezone: string;
	currentEnrollments: number;
	maxEnrollments: number;
	tutorId: number;
	tutorFirstName: string;
	tutorLastName: string;
	seatsAvailable: number;
	country: string;
}

export interface IPcnaRegistrationConfirmationModel extends IOrderConfirmation {
	registeredTrainees: IPcnaTraineeModel[];
	failedTrainees: IPcnaTraineeModel[];
}

export interface IC4CTRegistrationConfirmationModel extends IOrderConfirmation {
	registeredTrainees: IC4CTTraineeModel[];
	failedTrainees: IC4CTTraineeModel[];
}

export interface IPcnaTraineeModel {
	firstName: string;
	lastName: string;
	fullName: string;
	userId: string;
	orderDetailId: number;
}
export interface IC4CTTraineeModel {
	firstName: string;
	lastName: string;
	fullName: string;
	userId: string;
	orderDetailId: number;
}

export interface IPcnaTraineeInfo {
	traineesInCarts: ICertificationTraineeUserModel[];
	availableTrainees: ICertificationTraineeUserModel[];
	unavailableTrainees: ICertificationTraineeUserModel[];
}
export interface IC4CTTraineeInfo {
	traineesInCarts: ICertificationTraineeUserModel[];
	availableTrainees: ICertificationTraineeUserModel[];
	unavailableTrainees: ICertificationTraineeUserModel[];
}

export interface IProctorUStudent {
	hasStudentProfile: boolean;
	userId: string;
	firstName: string;
	lastName: string;
	phone: string;
	email: string;
	timeZoneId: string;
}

export interface IProctorUTimeZone {
	id: string;
	gmtOffset: number;
	displayName: string;
	isDaylightSavings: boolean;
	timeZoneNumber: number;
}

export interface ITechnicianSummaryModel {
	certifications: ITechnicianCertfication[];
	countTotalTechs: number;
	countAiCertified: number;
	countAiRegistered: number;
	countApCertified: number;
	countApRegistered: number;
	countClCertified: number;
	countClRegistered: number;
	countPcnaCertified: number;
	countPcnaRegistered: number;
	countC4CTCertified: number;
	countC4CTRegistered: number;
}

export interface ITechnicianCertfication {
	fullName: string;
	technicianId: string;
	dealerId: string;
	enrollmentId: number;
	certificationType: CertificationType;
	currentStatus: CertificationStatus;
	registrationDate: Date;
	certificationDate: Date;
	rebateStartDate: Date;
	lastCourse: number;
}