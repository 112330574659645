import {
	IAccelerationGoalModel,
	ICloudinaryUploadResult,
	IGoal,
} from "@app/shared-elements/shared-elements.models";
import {
	ICacheable,
	IGenericResponse,
	Portals,
} from "@app/shared/shared.models";
import { CertificationType } from "@app/training/training.models";

export interface IUser extends ICacheable {
	userId: string;
	firstName: string;
	lastName: string;
	email: string;
	personalEmail: string;
	addDate: Date | null;
	lastVisitDate: Date | null;
	lastLockoutDate: Date | null;
	lastIpAddress: string;
	visits: number;
	dayPhone: string;
	accountId: string;
	isActive: boolean;
	emailPassword: string;
	lastPasswordChangeDate: Date | null;
	lastChangeDate: Date | null;
	enabled: boolean;
	subDealerId: string;
	parentAccountId: string;
	portalAccess: Portals;
	isSubscribed: boolean | null;
	securityRoles: SecurityRoles;
	primaryRole: BusinessRole;
	secondaryRoles: BusinessRole;
	loginName: string;
	certificationCode: string;
	jobTitle: string;
	salesforceContactId: string;
	technicianId: string;
	token: string;
	loggingInWithNewPassword: boolean;
	workEmailVerified: boolean;
	displayName: string;
	activeCartId: number;

	account?: Account;
}

export interface IEditUser extends IUser {
	password: string;
}

export interface ICurrency {
	code: string;
	symbol: string;
	altSymbol: string;
}

export enum SapInstanceType {
	None,
	US,
	EMEA,
	Australia,
}

export enum AccountRole {
	None = 0,
	Internal = 1,
	Showroom = 2,
	Builder = 4,
}

export const AccountRoleMap = new Map<AccountRole, string>([
	[AccountRole.Internal, "Internal"],
	[AccountRole.Showroom, "Certified Showroom"],
	[AccountRole.Builder, "Builder"],
]);

export enum AccountSecurityRole {
	None = 0,
	MicrositeManagement = 1,
	RmaManagement = 1 << 1,
	UserManagement = 1 << 2,
	LeadsManagement = 1 << 3,
	ControllerManagement = 1 << 4,
	MsrpViewing = 1 << 5,
	DesignServices = 1 << 6,
	Support = 1 << 7,
	Education = 1 << 8,
	MarketingResources = 1 << 9,
	Advertising = 1 << 10,
	WhatsNew = 1 << 11,
	PriceListViewing = 1 << 12,
	OrderHistoryViewing = 1 << 13,
	CMS = 1 << 14,
}

export const AccountSecurityRoleMap = new Map<AccountSecurityRole, string>([
	[AccountSecurityRole.MicrositeManagement, "Microsite Management"],
	[AccountSecurityRole.RmaManagement, "Rma Management"],
	[AccountSecurityRole.UserManagement, "User Management"],
	[AccountSecurityRole.LeadsManagement, "Leads Management"],
	[AccountSecurityRole.ControllerManagement, "Controller Management"],
	[AccountSecurityRole.MsrpViewing, "View MSRP"],
	[AccountSecurityRole.DesignServices, "View Design Services"],
	[AccountSecurityRole.Support, "View Support"],
	[AccountSecurityRole.Education, "View Education"],
	[AccountSecurityRole.MarketingResources, "View Marketing Resources"],
	[AccountSecurityRole.Advertising, "View Advertising"],
	[AccountSecurityRole.WhatsNew, "View What's New"],
	[AccountSecurityRole.PriceListViewing, "View Price List"],
	[AccountSecurityRole.OrderHistoryViewing, "View Order History"],
	[AccountSecurityRole.CMS, "CMS"],
]);

export interface Account extends ICacheable {
	id: string;
	parentAccount: string;
	accountName: string;
	mainContactId: string;
	purchaseLimit: number | null;
	priceListCode: string;
	defaultShippingOptionId: number;
	defaultShippingAddressId: number | null;
	defaultBillingOptionId: number | null;
	defaultBillingAddressId: number | null;
	emailAddress: string;
	website: string;
	active: string;
	isActive: boolean;
	industryCode: string;
	premierStatus: string;
	terminationStatus: string;
	freeShippingThreshold: number | null;
	freeSnapShippingThreshold: number | null;
	defaultFinalDestinationAddressId: number | null;
	isTollBrothers: boolean | null;
	portalAccess: Portals;
	primaryPortal: Portals;
	buyingGroup: string;
	isLeadsDashboardAccount: boolean | null;
	isPinnacle: boolean | null;
	sapInstance: SapInstanceType | null;
	accountRoles: AccountRole | null;
	accountSecurityRoles: AccountSecurityRole | null;
	vatStatus: string;
	taxExempt: string;
	phone1: string;
	phoneFax: string;
	currency: ICurrency;
	isInternational: boolean;
	isDistributor: boolean;
	subDealers?: string[];
	showMsrp: boolean;
	// TODO: merged loyalty
	snapRewardsTier: string;

	billingAddressIds: number[];
	shippingAddressIds: number[];
	finalDestinationAddressIds: number[];
	migrationStartedDate: Date | string;
	migrationCompletedDate: Date | string;
	migrationScheduledDate: Date | string;
	isReadOnly: boolean;
}

export enum SecurityRoles {
	None = 0,
	InternalSuperUser = 1,
	InternalSalesImpersonation = 1 << 1,
	InternalMarketingAdmin = 1 << 2,
	InternalContentReviewer = 1 << 3,
	InternalSupportAdmin = 1 << 4,
	InternalSupportModerator = 1 << 5,
	PurchasingUser = 1 << 6,
	PriceViewing = 1 << 7,
	ViewCompInfo = 1 << 8,
	ViewOrderInfo = 1 << 9,
	AcctUserAdmin = 1 << 10,
	OnlineRma = 1 << 11,
	MarketingToolKit = 1 << 12,
	Dashboard = 1 << 13,
	MsrpPriceViewing = 1 << 15,
	InternalProductAdmin = 1 << 16,
	InternalSalesAdmin = 1 << 17,
	InternalEducationAdmin = 1 << 18,
	InternalFirmwareAdmin = 1 << 19,
	InternalPlaceDealerOrders = 1 << 20,
	All = (1 << 21) - 1,
}

export interface ISecurityRoleDetail extends ICacheable {
	roleId: number;
	roleCode: string;
	roleName: string;
	isInternal: boolean;
	operator: number;
}

export enum BusinessRole {
	BusinessOwner = 1,
	Manager = 2,
	Marketer = 4,
	Purchaser = 8,
	Programmer = 16,
	Installer = 32,
}

export enum SecondaryRoles {
	Manager = 2,
	Marketer = 4,
	Purchaser = 8,
	Programmer = 16,
	Installer = 32,
}

export const PrimaryRoleNames = new Map<BusinessRole, string>([
	[BusinessRole.BusinessOwner, "Business Owner"],
	[BusinessRole.Manager, "Manager"],
	[BusinessRole.Marketer, "Marketer"],
	[BusinessRole.Purchaser, "Purchaser"],
	[BusinessRole.Programmer, "Technician: Programmer"],
	[BusinessRole.Installer, "Technician: Installer"],
]);

export const SecondaryRoleNames = new Map<BusinessRole, string>([
	[BusinessRole.Manager, "Manager"],
	[BusinessRole.Marketer, "Marketer"],
	[BusinessRole.Purchaser, "Purchaser"],
	[BusinessRole.Programmer, "Technician: Programmer"],
	[BusinessRole.Installer, "Technician: Installer"],
]);

export interface IUpdateDealerRoleModel {
	userId: string;
	primaryRole: BusinessRole;
	secondaryRoles: SecondaryRoles;
}

export function userHasSecurityFlag(
	user: IUser,
	flag: SecurityRoles,
	ignoreSuper = false
) {
	return (
		user != null && hasSecurityFlag(user.securityRoles, flag, ignoreSuper)
	);
}

export function hasSecurityFlag(
	val: SecurityRoles,
	flag: SecurityRoles,
	ignoreSuper = false
) {
	return (
		!!(val & flag) ||
		(!ignoreSuper && !!(val & SecurityRoles.InternalSuperUser))
	);
}

export function accountHasAccountRoleFlag(
	account: Account,
	flag: AccountSecurityRole
) {
	return (
		account != null &&
		hasAccountRoleFlag(account.accountSecurityRoles, flag)
	);
}

export function hasAccountRoleFlag(
	val: AccountSecurityRole,
	flag: AccountSecurityRole
) {
	return !!(val & flag);
}

export interface ISalesQuarterModel {
	amount: number;
	quarter: number;
	year: number;
}

export interface IAccountSalesInfo extends ICacheable {
	accountId: string;
	currentYear: ISalesQuarterModel[];
	previousYear: ISalesQuarterModel[];
	yoyGrowthPercent: number;
	ytdRevenue: number;
}

export interface IBakpakCodes extends ICacheable {
	userId: string;
	accountCode: string;
	technicianCode: string;
}

export interface IUserCertification {
	id: number;
	dealerId: string;
	dealerName: string;
	contactName: string;
	certificationType: CertificationType;
	certificationName: string;
	completionDate: string;
	createdDate: string;

	enrollmentId: number;
}

export interface IUserCertificationsInfo extends ICacheable {
	certifications: IUserCertification[];
	userId: string;
}

export class LanguageType {
	id: number;
	name: string;
	code: string;
	active: boolean;
}

export interface IPhoneVerificationResult {
	requestId: string;
	numberIsInUse: boolean;
	errorMessage: string;
}

export interface SearchModel<T> {
	items: T[];
	totalCount: number;
}

export interface IUsageResult {
	email: string;
	emailInUse: boolean;
}

export interface IEmailVerificationResult {
	success: boolean;
	message: string;
	code: string;
}

export interface AccountOrderHistory extends ICacheable {
	orders: IOrderHistoryItem[];
	accountId: string;
}

export interface IOrderHistoryItem {
	accountId: string;
	orderId: number;
	webOrderId: number;
	orderType: string;
	orderDate: string;
	poNumber: string;
	total: number;
	freight: number;
	tax: number;
	currency: string;

	subtotal: number;

	details: IOrderHistoryItemDetail[];
}

export interface IOrderHistoryItemDetail {
	sku: string;
	productName: string;
	lineStatus: string;
	deliveryNumber: number;
	quantity: number;
	unitPrice: number;
	unitDiscount: number;
	lineFreight: number;
	lineTotal: number;
	currency: string;
	trackingNumber: string;
	trackingUrl: string;
	paintManufacturer: string;
	paintCode: string;
	veneerFinish: string;
	grillType: string;
	grillPaintCode: string;
	mountType: string;
	warehouse: string;
	width: number;
}

export interface IOrderHistoryItemDetailInfo extends ICacheable {
	webOrderId: number;
	details: IOrderHistoryItemDetail[];
}

export interface ISubDealerLocatorModel extends ICacheable {
	distributorId: string;
	dealerId: string;
	subDealerId: string;
	email: string;
	website: string;
	phone: string;
	created: Date;
	modified: Date;
	published: boolean;
	dealerStatus: string;
	company: string;
	address: string;
	address2: string;
	city: string;
	state: string;
	zip: string;
	country: string;
	latitude: number;
	longitude: number;
}

export interface IDownloadFilter {
	includePartnerProducts: boolean;
	includeControl4Products: boolean;
	includeDealerPricing: boolean;
	includeMsrpPricing: boolean;
}

export enum FileType {
	Xls,
	Csv,
	Pdf,
}

export interface IUpgradeRequest {
	accountId: string;
	customerFirstName: string;
	customerLastName: string;
	customerEmail: string;
	installDate: string;
	programType: string;
	comments: string;
	onlineOrderNumber: string;
	dealerContact: string;
	files: IUpgradeRequestFile[] | ICloudinaryUploadResult[];
}

export interface IUpgradeRequestFile extends ICacheable {
	id: number;
	upgradeRequestId: number;
	name: string;
	date: Date;
	relativePath: string;
	accountId: string;
}

export interface IDealerStatusProgramInfoModel {
	statusTierBenefits: IDealerStatusTierBenefits[];
	statusTierRequirements: IDealerStatusTierRequirementsModel[];
	triadRebateTiers: IGoal[];
	dealerRebateTiers: IDealerRebateTier[];
	accelerators: IAccelerationGoalModel[];
	currentStatus: string;
}

export interface IDealerStatusTierBenefits {
	tierName: string;
	maximumDealerRebate: number;
	maximumTriadDealerRebate: number;
	freeShippingMinimum: number;
	inDealerLocator: boolean;
	designProposalServices: string;
	hasShowroomDesignSupport: boolean;
	selectTechSupport: string;
	canSubmitOnlineRma: boolean;
	isPremierTechSupport: boolean;
}

export interface IDealerRebateTier {
	tierName: string;
	minimumControllersAmount: number;
	goals: IGoal[];
}

export interface IDealerStatusTierRequirementsModel {
	tierName: string;
	minSalesAmount: number;
	minControllerPurchases: number;
	approvedDemoFacility: boolean;
	minApcTechniciansTrained: number;
	minPcnaTechniciansTrained: number;
	goodStandingCreditAndTechSupport: boolean;
}

export interface ITrainingSsoResponse {
	ssoUrl: string;
}

export interface ITrainingEnrollmentResponse extends IGenericResponse {
	learningObjectUrl: string;
}

export interface IRebateProgress {
	earnings: number;
	rewardableEarnings: number;
	earnedReward: number;
	currentRewardPercent: number;
	minimumSales: number;
	maximumSales: number;
	nextGoalReward: number;
	percentComplete: number;
	amountNeeded: number;
	optimalNextGoalReward: number;
	optimalOrderDiscount: number;
	lastUpdated: Date | string;
	atMax: boolean;
}

export interface IRebateProgressData extends ICacheable {
	accountId: string;
	triadRebate: IRebateProgress;
	generalRebate: IRebateProgress;
	totalRebate: number;
	lastUpdated: Date | string;
}

export interface ITrustkitError {
	message: string;
	code: string;
}

export enum FeatureFlags {
	AccountStatus = "accountStatus",
	AusInvoices = "ausInvoices",
	CognitoForgotPassword = "cognitoForgotPassword",
	DealerRebate = "dealerRebate",
	EmeaInvoices = "emeaInvoices",
	EnableLeads20 = "enableLeads20",
	EnforceUsernames = "enforceUsernames",
	Firmware20 = "firmware20",
	FullStoryEnabled = "FullStoryEnabled",
	GoogleAssistant = "googleAssistant",
	IncludeSnapFreeShipping = "includeSnapFreeShipping",
	LeadDashboard = "leadDashboard",
	MigrateUsers = "migrateUsers",
	PersistDealerAttributes = "persistDealerAttributes",
	PersistPasswordChange = "persistPasswordChange",
	ShowStockStatus = "showStockStatus",
	SwitchCart = "switchCart",
	CustomerUpgradesDashboard = "customerUpgradesDashboard",
	TriadDiscount = "triadDiscount",
	TriadRebate = "triadRebate",
	RmaLocalPickup = "rmaLocalPickup",
	RetrieveCertsFromSalesforce = "retrieveCertsFromSalesforce",
	UseWebhelpEmail = "useWebhelpEmail",
	ShowLightingMicrosite = "showLightingMicrosite",
	UseNewPcnaChanges = "useNewPcnaChanges",
	MergedLoyaltyProgram = "mergedLoyaltyProgram",
	ShowContemporaryEngraverOptions = "showContemporaryEngraverOptions",
	EnableContemporaryKeycapsUploadXML = "enableContemporaryKeycapsUploadXML",
	DisablePakedgeForPCNA = "disablePakedgeForPCNA",
	EnableShift4CreditCardProcessing = "enableShift4CreditCardProcessing",
	Gen4Engraving = "gen4engraving",
	Gen4EngravingEmea = "Gen4EngravingEmea",
	IsGen4EnabledUser = "isGen4EnabledUser",
	UseNewC4CTChanges = "useNewC4CTChanges",
	DisablePakedgeForC4CT = "disablePakedgeForC4CT",
}
