import { CartType, ISavedCart } from "@app/checkout/checkout.models";

export enum ContractType {
	TermsOfSales = 1,
	PcnaTermsOfUse = 2,
	C4CTTermsOfUse = 3,
}

export interface IContractModel extends ICacheable {
	id: number;
	title: string;
	terms: string;
	isSigned: boolean;
	dateAdded: string;
	contractType: ContractType;
}

export interface SiteFeatureFlag {
	key: string;
	flag: SiteFeatureFlagType;
}

export interface Settings {
	usernameRegex: string;
	passwordRegex: string;
	upgradeCategories: UpgradeCategories;
	snapCategoryId: number;
}

interface UpgradeCategories {
	controllers: number;
	doorStations: number;
	touchscreens: number;
	triadOne: number;
}

export enum SiteFeatureFlagType {
	Off,
	On,
	Internal,
}

export const FeatureFlagTypeMap = new Map([
	[SiteFeatureFlagType.Off, "Off"],
	[SiteFeatureFlagType.On, "On"],
	[SiteFeatureFlagType.Internal, "Internal Only"],
]);

export enum Portals {
	DealerUs = 1,
	DealerEmea = 2,
	Pakedge = 4,
	Triad = 8,

	// Should always equal 2n-1 where n = the max value in the enum
	All = 15,
}

export const PortalNames = new Map<Portals, string>([
	[Portals.DealerUs, "Control4 US"],
	[Portals.DealerEmea, "Control4 EMEA"],
	[Portals.Pakedge, "Pakedge"],
	[Portals.Triad, "Triad"],
]);

export const BrandNames = new Map<Portals, string>([
	[Portals.DealerUs | Portals.DealerEmea | Portals.Pakedge | Portals.Triad, "All"],
	[Portals.DealerUs, "Control4"],
	[Portals.DealerEmea, "Control4"],
	[Portals.Pakedge, "Pakedge"],
	[Portals.Triad, "Triad"],
]);

export enum CertificationStatus {
	Unregistered = 0,
	Registered = 10,
	Shipped = 15,
	CourseCompleted = 20,
	ExamScheduled = 30,
	ExamComplete = 35,
	ExamFailed = 40,
	Certified = 50,
}

export const CertificationStatusNames = new Map<CertificationStatus, string>([
	[CertificationStatus.Unregistered, "Unregistered"],
	[CertificationStatus.Registered, "Registered"],
	[CertificationStatus.Shipped, "Shipped"],
	[CertificationStatus.CourseCompleted, "Course Completed"],
	[CertificationStatus.ExamScheduled, "Exam Scheduled"],
	[CertificationStatus.ExamComplete, "Exam Complete"],
	[CertificationStatus.ExamFailed, "Exam Failed"],
	[CertificationStatus.Certified, "Certified"],
]);

export interface IControl4ContactsModel extends ICacheable {
	accountId: string;
	insideManagerContact: IContact;
	salesManagerContact: IContact;
	technicalSupport: string;
	techSupportInfo: string;
	technicalSupportPhone: string;
}

export interface ISalesContactsModel extends ICacheable {
	id: string;
	name: string;
	accountId: string;
	coreInsideRep: ISalesContactModel;
	coreOutsideRep: ISalesContactModel;
	coreSalesEngineer: ISalesContactModel;
	localRep: ISalesContactModel;
	nationalRep: ISalesContactModel;
	securityBdm: ISalesContactModel;
	securityRep: ISalesContactModel;
}

export interface ISalesContactModel {
	id: string;
	firstName: string;
	lastName: string;
	phone: string;
	mobilePhone: string;
	email: string;
}

export interface IContact {
	name: string;
	phone: string;
	fax: string;
	email: string;
	company: string;
}

export interface IControlColor {
	id: number;
	name: string;
	cssClass: string;
	type: ControlTypes;
}

export interface IPropaganda {
	id: number;
	mediaUrl: string;
	aligned: string;
	buttonText: string;
	buttonColor: string;
	buttonClass: string;
	buttonUrl: string;
	visibleInPortals: Portals;
	visibleInPages: Pages;
	startDate: Date;
	endDate: Date;
	scheduleType: ScheduleType;
	vAligned: string;
	priority: number;
	propagandaTypeId: PropagandaType;
	lines: IPropagandaLine[];
	dealers: IPropagandaDealer[];
	categories: ICategoryModel[];
	productSkus: string[];
}

export interface IPropagandaLine {
	id?: number;
	propagandaId?: number;
	text: string;
	class: string;
	tag: string;
	color: string;
	position?: number;
}

export interface IPropagandaDealer {
	propagandaId: number;
	dealerId: string;
}

export interface IPropagandaPageChange {
	page: PropagandaPages;
	type: PropagandaType;
}

export enum PropagandaAlignment {
	Left = "Left",
	Right = "Right",
	Center = "Center",
	Top = "Top",
	Bottom = "Bottom",
}

export enum PropagandaPages {
	ManagementPage = 1,
	NewEditPage = 2,
	SortPage = 3,
}

export enum PropagandaType {
	Slide = 1,
	Ad = 2,
}

export const PropagandaTypeNames = new Map([
	[PropagandaType.Slide, "Slide"],
	[PropagandaType.Ad, "Ad"],
]);

export enum Pages {
	Home = 1,
	Product = 2,
	All = 3,
}

export const PagesNames = new Map([
	[Pages.Home, "Home Page"],
	[Pages.Product, "Product Page"],
]);

export enum ControlTypes {
	Text = 1,
	Button = 2,
	All = 3,
}

export enum LocationType {
	Product = 1,
	Category = 2,
}

export enum ScheduleType {
	Draft = 0,
	Live = 1,
	Scheduled = 2,
	Archive = 3,
}

export interface Schedule {
	id: number;
	start?: Date | string;
	end?: Date | string;
	type: ScheduleType;
}

export const ScheduleTypeNames = new Map<ScheduleType, string>([
	[ScheduleType.Draft, "Draft"],
	[ScheduleType.Live, "Immediate"],
	[ScheduleType.Scheduled, "Scheduled"],
	[ScheduleType.Archive, "Archive"],
]);

export interface ICategoryModel extends ICacheable {
	categoryId: number;
	parentId: number;
	catalogId: number;
	categoryCode: string;
	categoryPath: string;
	categoryName: string;
	description: string;
	categoryType: string;
	sortCode: number;
	visibleFlag: string;
	securityLevel: number;
	categoryOptions: string;
	defaultShippingLeadMessage: string;
	longCategoryName: string;
	categoryImageId: number;
	filtersEnabled: boolean;
	imageFilePath: string;
	categoryImage: IMedia;

	skus: string[];
	skuAndStockStatusPairs: SkuWithStockStatus[];
	filters: IFilterGroupModel[];
}

export enum StockStatusTypes {
	InStock = 1,
	OutOfStock = 2,
	BuiltToOrder = 3,
	FulfilledByPartner = 4,
}

export interface SkuWithStockStatus {
	sku: string;
	stockStatusType: StockStatusTypes;
}

export interface StockStatusFilter {
	stockStatusType: StockStatusTypes;
	active: boolean;
	count: number;
}

export const StockStatusTypeNames = new Map<StockStatusTypes, string>([
	[StockStatusTypes.InStock, "In Stock"],
	[StockStatusTypes.OutOfStock, "Out of Stock"],
	[StockStatusTypes.BuiltToOrder, "Built to Order"],
	[StockStatusTypes.FulfilledByPartner, "Fulfilled by Partner"],
]);

export interface IFilterGroupModel {
	id: number;
	label: string;
	filters: IFilterModel[];
}
export interface IFilterInfo {
	groupId: number;
	filterFlags: number;
}

export interface IFilterModel {
	label: string;
	value: number;
	count: number;

	selected?: boolean;
}

export interface IMedia {
	id: number;
	name: string;
	primaryId: string;
	visibleInPortals: Portals;
	mediaTypeId: MediaType;
	url: string;
	thumbnailUrl: string;
	sortOrder: number;
}

export enum MediaType {
	Image = 1,
	Video = 2,
	Attachment = 3,
	SpecSheet = 5,
	Downloads = 6,
	Documents = 7,
	Firmware = 8,
}

export const mediaLabels = new Map<MediaType, string>([
	[MediaType.Image, "Image"],
	[MediaType.Video, "Video"],
	[MediaType.Attachment, "Attachment"],
	[MediaType.SpecSheet, "Spec Sheet"],
	[MediaType.Downloads, "Downloads"],
	[MediaType.Documents, "Documents"],
	[MediaType.Firmware, "Firmware"],
]);

export interface IDocumentationDoc {
	id: number;
	productSku: string;
	productName: string;
	docType: string;
	category: string;
	latestDownloadLink: string;
	externalLink: boolean;
	thumbnailUrl: string;
	revisions: IDocumentRevision[];
	friendlyName: string;
	showRevisions: boolean;
	language: string;
}

export class Driver {
	copyright: string;
	name: string;
	manufacturer: string;
	creator: string;
	dateCreated: string;
	model: string;
	filename: string;
	certified: string;
	dateLastModified: string;
	controlMethod: string;
	primaryProxy: string;
	version: string;
	url: string;
}

export class IDriversResult {
	docs: Driver[];
	numFound: number;
	filterGroups: IFilterGroupModel[];
}

export interface IDocumentRevision {
	number: string;
	downloadLink: string;
	createdDate: string;
}

export interface IDocumentationFilters {
	products: string[];
	categories: string[];
	docTypes: string[];
	releases: string[];
}

export interface IDocumentationPage {
	success: boolean;
	count: number;
	filters: IDocumentationFilters;
	documents: IDocumentationDoc[];
}

export interface ILoadCompatibilityPart {
	id: number;
	sku: string;
	lightId: number;
	light: ILoadCompatibilityPartLight;
	composerColdStartLevel: string;
	composerColdStartTime: string;
	estimatedNumOfLoads: number;
	minDimLevel: number;
	maxDimLevel: number;
	minDimLevelSwitchLeg: number;
	maxDimLevelSwitchLeg: number;
	minDimLevelForwardPhase: number;
	maxDimLevelForwardPhase: number;
	minDimLevelFordwardPhaseSwitchLeg: number;
	maxDimLevelFordwardPhaseSwitchLeg: number;
	minDimLevelReversePhase: number;
	maxDimLevelReversePhase: number;
	minDimLevelReversePhaseSwitchLeg: number;
	maxDimLevelReversePhaseSwitchLeg: number;
	minDimLevelForwardPhaseSwitchLeg: number;
	maxDimLevelForwardPhaseSwitchLeg: number;
	estimatedNumOfload: string;

	showDetails: boolean;
}

export interface ErrorList {
	errors: string[];
}

export class ILoadCompatibilityFilter {
	sku = "";
	brandName = "";
	bulbFixtureType = "";
	technologyTypeName = "";
	voltage = "";
}

export interface ILoadCompatibilityPartLight {
	modelName: string;
	modelNumber: number;
	bulbType: string;
	baseType: string;
	voltage: string;
	wattage: number;
	lumens: number;
	bulbLifeHours: string;
	additionalModelInformation: string;
	brand: ILoadCompatibilityPartLightBrand;
	type: ILoadCompatibilityPartLightType;

	controlType: string;
	dimmable: string;
	hiLumeASeriesDriver: string;
	turnOnInstantly: string;
	minimumPowerRequired: string;
	recommendForwardPhase: string;
	recommendReversePhase: string;
	forwardReversePhaseCompatible: string;
	switchLegMode: string;
	parallelSwitchLegMode: string;
	noiseDimming: string;
	flickerOnTurnOn: string;
	flickerOnDim: string;
	mfrRecommendLoad: string;
	coldStartTime: string;
}

export interface ILoadCompatibilityPartLightBrand {
	brandId: number;
	brandName: string;
}

export interface ILoadCompatibilityPartLightType {
	typeId: number;
	typeName: string;
}

export interface IAddress extends ICacheable {
	id: number;
	name: string;
	line1: string;
	line2: string;
	city: string;
	stateProvince: string;
	postalCode: string;
	country: string;
	countryAbbr: string;
	displayValue: string;
	dropShip: boolean;
}

export interface IAccountBalanceResult {
	details: IAccountBalanceLineItem[];
	accountId: string;
	valid: boolean;
}

export interface IAccountBalanceLineItem {
	accountId: string;

	transactionId: string;
	transactionType: string;
	transactionDate: string;
	amount: number;
	earlyPayAmount: number;
	lineMemo: string;
	referenceNumber: string;
	customerReference: string;
	purchaseDate: string;
	dueDate: string;
	currency: string;
	agingBucket: string;
	isPaid: boolean;
	orderType: string;
	paymentType: string;

	notDue: number;
	oneThirty: number;
	thirtyOneSixty: number;
	sixtyOneNinety: number;
	ninetyOneTwenty: number;
	oneTwentyPlus: number;

	isSelected: boolean;
}

export class AccountBalanceTotal {
	balanceDue = 0;
	earlyPayBalanceDue = 0;
	notDue = 0;
	oneThirty = 0;
	thirtyOneSixty = 0;
	sixtyOneNinety = 0;
	ninetyOneTwenty = 0;
	oneTwentyPlus = 0;
	valid: boolean;
}

export interface IGenericResponse<T = undefined> {
	readonly success: boolean;
	readonly warningMessage: string;
	readonly errorMessage: string;
	readonly result?: T;
}

export interface ICacheable {
	expiration?: Date;
}

export interface IValueCache<T> extends ICacheable {
	item: T;
}

export interface IDealerLocatorLocation {
	id: number;
	dealerId: string;
	lineNum: number;
	cardName: string;
	street: string;
	block: string;
	city: string;
	state: string;
	zipCode: string;
	country: string;
	email: string;
	website: string;
	phone: string;
	premierStatus: string;
	active: boolean;
	lat: number;
	lng: number;
	geocodeStatus: string;
	geocodeDate: Date;
	syncStatus: string;
	salesToDate: number;
	type: string;
	url: string;
	balihooId: string;
	dealerType: string;
	salespersonName: string;
	fax: string;
	uklegalAgreement: boolean;
	balihooUrlUpdateDate: Date;
	marketingToolkitAccess: boolean;
	marketingToolkitPurchaseDate: Date;
	language: string;
	isPakedge: boolean;
	isCertifiedShowroom: boolean;
	isMicrositeParticipant: boolean;
	isPcnaCertified: boolean;
	isCentralizedLightingCertified: boolean;
	isEventParticipant: boolean;
	isAddressHidden: boolean;
	percentageComplete: any;

	micrositeCompany: IMicrositeCompanyModel;
	micrositeEmployeeBio: IMicrositeEmployeeBioModel[];
	micrositeGalleryImages: IMicrositeGalleryImageModel[];
	micrositeSocialMedia: IMicrositeSocialMediaModel[];
	micrositeTestimonials: ITestimonialModel[];
}

export interface IMicrositeCompanyModel {
	dealerId: string;
	lineNum: number;
	eventDescription: string;
	directions: string;
	hoursOfOperation: string;
	logo: string;
	landmarks: string;
	areasServed: string;
	aboutUs: string;
	showroomImage: string;
	googlePlace: string;
	solutions: number;
	associations: number;
}

export interface IMicrositeEmployeeBioModel {
	id: number;
	dealerId: string;
	lineNum: number;
	name: string;
	description: string;
	photoUrl: string;
}

export interface IMicrositeGalleryImageModel {
	id: number;
	dealerId: string;
	lineNum: number;
	photoUrl: string;
	caption: string;
}

export interface IMicrositeSocialMediaModel {
	id: number;
	dealerId: string;
	lineNum: number;
	type: number;
	url: string;
}

export interface ITestimonialModel {
	id: number;
	name: string;
	testimonialText: string;
	state: string;
	dealerId: string;
	lineNum: number;
}

export enum Solutions {
	SmartLighting = 1,
	ClimateControl = 2,
	HomeSecurity = 4,
	HomeNetwork = 8,
	HomeTheater = 16,
	HomeAudio = 32,
	HomeIntercom = 64,
	OneRoomRemote = 128,
	VoiceControl = 256,
}

export const SolutionNames = new Map<Solutions, string>([
	[Solutions.SmartLighting, "Smart Lighting"],
	[Solutions.ClimateControl, "Climate Control"],
	[Solutions.HomeSecurity, "Home Security"],
	[Solutions.HomeNetwork, "Home Network"],
	[Solutions.HomeTheater, "Home Theater"],
	[Solutions.HomeAudio, "Home Audio"],
	[Solutions.HomeIntercom, "Home Intercom"],
	[Solutions.OneRoomRemote, "One-Room Remote"],
	[Solutions.VoiceControl, "Voice Control"],
]);

export enum Associations {
	CEDIA = 1,
	NAHB = 2,
	HTSA = 4,
	ProSource = 8,
	HTA = 16,
	HTSN = 32,
}

export const AssociationBadgeUrls = new Map<Associations, string>([
	[Associations.CEDIA, "https://res.cloudinary.com/control4/image/upload/e_make_transparent/c_pad,h_40,w_150,f_auto,q_100/v1553877937/dealer-badge-cedia-bw.png"],
	[Associations.NAHB, "https://res.cloudinary.com/control4/image/upload/e_make_transparent/c_pad,h_40,w_150,f_auto,q_100/v1553877937/dealer-badge-nahb-bw.png"],
	[Associations.HTSA, "https://res.cloudinary.com/control4/image/upload/c_pad,h_40,w_150,f_auto,q_100/v1553877937/dealer-badge-htsa-bw.png"],
	[Associations.ProSource, "https://res.cloudinary.com/control4/image/upload/e_make_transparent/c_pad,h_40,w_150,f_auto,q_100/v1553877937/dealer-badge-prosource-bw.png"],
	[Associations.HTA, "https://res.cloudinary.com/control4/image/upload/e_make_transparent/c_pad,h_40,w_150,f_auto,q_100/v1559763435/dealer-badge-hta-bw.svg"],
	[Associations.HTSN, "https://res.cloudinary.com/control4/image/upload/e_make_transparent/c_pad,h_40,w_150,f_auto,q_100/v1555429609/dealer-badge-htsn-bw.png"],
]);

export enum SocialMediaTypes {
	Twitter = 1,
	Facebook = 2,
	Instagram = 3,
	YouTube = 4,
	Pinterest = 5,
	Linkedin = 6,
	Blog = 7,
}

export interface IError {
	error?: string;
	warning?: string;

	accountId?: string;

	// Front-end only
	ttl?: number;
}

// TODO: Pretty sure this could be refactored to match other error messages
export interface IErrorInfo {
	errorMessage: string;
	propertyName?: string;
}

export interface IGenericErrorListResponse {
	errors: IErrorInfo[];
	success: boolean;
}

export enum SubmitStateType {
	Ready,
	Submitting,
	Error,
	Success,
}

export interface IErrorListModel {
	errors: IErrorInfo[];
	success: boolean;
}

export interface IDictionary<T1, T2> {
	[index: string]: T2;
}

export interface CartGroup {
	code: CartType;
	name: string;
	items: ISavedCart[];
	display?: boolean;
}

export const CartGroupNames = new Map<string, string>([
	["K", "Keycap Engraving"],
	["D", "Design Services"],
	["N", "Saved"],
	["U", "Upgrades"],
]);

export enum PasswordErrors {
	InvalidPassword = "Password must be between eight and twenty characters and include the following: one or more capital letter, one or more lowercase letter, one or more numerical digit, one or more special character.",
	MismatchedPassword = "Password and Confirm Password must match.",
}

export enum MicrositeTypes {
	Default = 0,
	C4Yourself = 1,
	CentralizedLighting = 2,
}

export const MicrositeNames = new Map<MicrositeTypes, string>([
	[MicrositeTypes.Default, "RSVP"],
	[MicrositeTypes.C4Yourself, "C4Yourself"],
	[MicrositeTypes.CentralizedLighting, "Lighting Microsite"],
]);

export const MicrositeShortURLCode = new Map<MicrositeTypes, string>([
	[MicrositeTypes.Default, "d"],
	[MicrositeTypes.C4Yourself, "c4y"],
	[MicrositeTypes.CentralizedLighting, "l"],
]);