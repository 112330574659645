import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CheckoutBillingInfo, IBillingModel, ICheckoutModel, IOrderConfirmation, IOrderTotalsModel, IPaymentModel, IProcessOrderResult, IShippingModel } from "@app/checkout/checkout.models";
import { IErrorListModel } from "@app/shared/shared.models";
import { IAppState } from "@app/store";
import { OrderPlacedSuccess, UpdateModifyingCart } from "@app/store/cart";
import { ErrorToast, WarningToast } from "@app/store/toast/toast.actions";
import { IC4CTRegistrationConfirmationModel, IPcnaRegistrationConfirmationModel } from "@app/training/training.models";
import { Store } from "@ngrx/store";
import { tap } from "rxjs/operators";

@Injectable({
	providedIn: "root",
})
export class CheckoutService {
	constructor(
		readonly http: HttpClient,
		private readonly store: Store<IAppState>
	) { }

	getCheckout(orderId: number) {
		return this.http.get<ICheckoutModel>("api/checkout/GetCheckout", {
			params: { orderId: orderId.toString() },
		});
	}

	getOrderTotals(orderId: number, includeCustomer = false) {
		return this.http.get<IOrderTotalsModel>("api/checkout/GetOrderTotals", {
			params: {
				orderId: orderId.toString(),
				customer: includeCustomer.toString(),
			},
		});
	}

	changeActiveCart(orderId: number) {
		return this.http.post<IOrderTotalsModel>("api/cart/ChangeActiveCart", {
			orderId: orderId.toString(),
		});
	}

	getShipping(orderId: number) {
		return this.http.get<IShippingModel>("api/checkout/GetShipping", {
			params: { orderId: orderId.toString() },
		});
	}

	getBilling(orderId: number) {
		return this.http.get<IBillingModel>("api/checkout/GetBilling", {
			params: { orderId: orderId.toString() },
		});
	}

	setOrderBillingAddress(billingAddressId: number, orderId: number) {
		return this.http.post<IOrderTotalsModel>(
			"api/checkout/SetOrderBillingAddress",
			{ billingAddressId, orderId }
		);
	}

	authorizeOrderPayment(orderId: number, billing: CheckoutBillingInfo) {
		this.store.dispatch(
			new UpdateModifyingCart({ orderId, modifying: true })
		);
		const paymentInfo = <IPaymentModel>{
			orderId,
			billingAddressId: billing.selectedBillingAddressId,
			paymentType: billing.selectedPaymentMethod,
		};

		if (billing.selectedPaymentMethod === "CreditCard") {
			paymentInfo.creditCardData = {
				cardType: billing.billingOptions.cardType,
				nameOnCard: billing.billingOptions.nameOnCard,
				creditCardNumber: billing.billingOptions.cardNumber,
				expirationMonth: billing.billingOptions.expirationMonth,
				expirationYear: billing.billingOptions.expirationYear,
				securityCode: billing.billingOptions.securityCode,
			};
		}

		if (billing.selectedPaymentMethod === "ManualCreditCard") {
			paymentInfo.creditCardData = {
				cardType: "VIS",
				nameOnCard: "Credit Card By Phone",
				creditCardNumber: "4111111111119999",
				expirationMonth: "01",
				expirationYear: "2099",
				securityCode: "999",
			};
		}

		return this.http
			.post<IErrorListModel>(
				"api/order/AuthorizeOrderPaymentForSync",
				paymentInfo
			)
			.pipe(
				tap((result) => {
					this.store.dispatch(
						new UpdateModifyingCart({ orderId, modifying: false })
					);
					if (!result.success) {
						this.store.dispatch(
							new WarningToast({
								message:
									"There was a problem applying the payment to your order.",
							})
						);
					}
				})
			);
	}

	getPaymentProviderRedirectUrl(orderId: number) {
		return this.http
			.get<any>("api/checkout/GetPaymentProviderRedirectUrl", {
				params: { orderId: orderId.toString() },
			})
			.pipe(
				tap((result) => {
					if (result) {
						if (result.error) {
							this.store.dispatch(
								new WarningToast({ message: result.error })
							);
						}
					}
				})
			);
	}

	processOrder(orderId: number, poReference: string = "") {
		this.store.dispatch(
			new UpdateModifyingCart({ orderId, modifying: true })
		);
		return this.http
			.post<IProcessOrderResult>("api/checkout/ProcessOrder", {
				orderId,
				poReference,
			})
			.pipe(
				tap((result) => {
					if (result.success) {
						if (result.error) {
							this.store.dispatch(
								new WarningToast({ message: result.error })
							);
						}
						this.store.dispatch(new OrderPlacedSuccess(result));
					} else {
						this.store.dispatch(
							new ErrorToast({
								message:
									result.error ||
									"There was a problem submitting your order.",
							})
						);
						this.store.dispatch(
							new UpdateModifyingCart({
								orderId,
								modifying: false,
							})
						);
					}
				})
			);
	}

	getConfirmation(orderId: number) {
		return this.http.get<IOrderConfirmation>(
			"api/checkout/GetConfirmation",
			{ params: { orderId: orderId.toString() } }
		);
	}

	getPcnaOrderId() {
		return this.http.get<number>("api/cart/GetPcnaCartId");
	}
	getC4CTOrderId() {
		return this.http.get<number>("api/cart/GetC4CTCartId");
	}

	registerPcnaTraineesForOrder(orderId: number) {
		return this.http.post<IPcnaRegistrationConfirmationModel>(
			"api/checkout/RegisterPcnaTrainees",
			orderId
		);
	}
	registerC4CTTraineesForOrder(orderId: number) {
		return this.http.post<IC4CTRegistrationConfirmationModel>(
			"api/checkout/RegisterC4CTTrainees",
			orderId
		);
	}

	getCvcMaxLength(cardType: string) {
		switch (cardType) {
			case "AMEX":
				return 4;
			default:
				return 3;
		}
	}
}