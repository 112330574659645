import { Component, Input, OnInit } from "@angular/core";
import { IUser, IUserCertification } from "@app/account/account.models";
import { UserFullNamePipe } from "@app/pipes/fullname.pipe";
import { UserService } from "@app/services/user.service";
import { IAppState } from "@app/store";
import { CertificationStatus, CertificationType, ICertificationTraineeUserModel } from "@app/training/training.models";
import { Store } from "@ngrx/store";

@Component({
	selector: "c4-certification-badge",
	templateUrl: "./certification-badge.component.html",
	styleUrls: ["./certification-badge.component.scss"],
})
export class CertificationBadgeComponent implements OnInit {
	private _certificate: IUserCertification;
	public isC4CertifiedTech: boolean = false;
	learningPathUrl: string;
	c4ctcourseprogress: ICertificationTraineeUserModel;
	@Input() set certificate(val: IUserCertification) {
		this._certificate = val;
		this.certified = !!(val && val.completionDate);
		if (val) {
			this.isC4CertifiedTech = val.certificationType === CertificationType.Control4CertifiedTechnician;
			console.log("this.isC4CertifiedTech:" + this.isC4CertifiedTech);
			this.populateCertificateInfo();
		}
	}
	get certificate() {
		return this._certificate;
	}
	// get isc4ct(): boolean {
	// 	console.log("Certification Type:" + this.certificate.certificationType);
	// 	return (this.certificate.certificationType === CertificationType.Control4CertifiedTechnician);
	// }
	@Input() user: IUser;
	title: string;
	learnMore: string;
	url: string;
	currentcerststatus: string;
	certified: boolean;

	constructor(readonly store: Store<IAppState>, readonly userService: UserService) { }

	ngOnInit() {
		this.loadMyC4CT();
	}

	private populateCertificateInfo() {
		switch (this.certificate.certificationType) {
			case CertificationType.Pcna:
				this.title = "Professional Certified Network Administrator";
				this.learnMore = "/education/pcna";
				break;
			case CertificationType.CentralizedLighting2012:
				this.title = "Centralized Lighting 2012";
				this.learnMore = undefined;
				break;
			case CertificationType.CentralizedLighting2019:
				this.title = "Centralized Lighting 2019";
				this.learnMore = undefined;
				break;
			case CertificationType.AutomationProgrammer:
				this.title = "Automation Programmer";
				this.learnMore = "/education/certification#course2";
				break;
			case CertificationType.AssociateInstaller:
				this.title = "Associate Installer";
				this.learnMore = "/education/certification#course1";
				break;
			case CertificationType.Control4CertifiedTechnician:
				this.title = "Control4 Certified Technician";
				this.learnMore = "/education/c4ct";
				break;
		}

		this.url = CertificationType.getImageUrl(this.certificate.certificationType, "c_scale,h_125", this.certified);
	}

	getCertPdf() {
		this.userService.downloadCert(this.certificate, new UserFullNamePipe().transform(this.user));
	}
	private loadMyC4CT() {
		this.userService.getUserC4CTProgress().subscribe(result => {
			if (result) {
				this.c4ctcourseprogress = result;
				if (this.c4ctcourseprogress.currentStatus == CertificationStatus.ExamScheduled) {
					this.currentcerststatus = "examscheduled";
				}
				else if (this.c4ctcourseprogress.currentStatus == CertificationStatus.Registered) {
					this.currentcerststatus = "Registered";
				}
				else if (this.c4ctcourseprogress.currentStatus == CertificationStatus.CourseCompleted) {
					this.currentcerststatus = "coursecompleted";
				}
				else if (this.c4ctcourseprogress.currentStatus == CertificationStatus.ExamComplete) {
					this.currentcerststatus = "ExamComplete";
				}
				else if (this.c4ctcourseprogress.currentStatus == CertificationStatus.ExamFailed) {
					this.currentcerststatus = "ExamFailed";
				}
				else if (this.c4ctcourseprogress.currentStatus == CertificationStatus.Certified) {
					this.currentcerststatus = "Certified";
				}
				console.log("Course-progress status:" + this.currentcerststatus);
				this.learningPathUrl = result.enrollmentId > 0 ? `https://control4.learnupon.com/lpaths/${result.enrollmentId}/content` : "https://control4.learnupon.com/dashboard?display=my_courses";
			}
		});
	}
}
